import React from 'react';
import Hold from './Hold';

function App() {
  return(
    <div>
      <Hold />
    </div>
  );
}

export default App;
