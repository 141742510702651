import React from "react";
import './NoiseGeneratorButtonStyles.css'
import './PresetSlider.css'

class NoiseGenerator extends React.Component {
  constructor(props) {
    super(props);
    this.audioContext = new (window.AudioContext || window.webkitAudioContext)();
    this.state = {
      isPlaying: false,
      volume: 0.2,
      cutoffControlValue: 45,
      Q: 1,
      cutoffControlValue1: 30,
      Q1: 1,
      presets: [
        { cutoffControlValue: 500, Q: 1, cutoffControlValue1: 500, Q1: 1 },
        { cutoffControlValue: 10000, Q: 5, cutoffControlValue1: 300, Q1: 1 },
        { cutoffControlValue: 450, Q: 3, cutoffControlValue1: 150, Q1: 1 },
        { cutoffControlValue: 1700, Q: 1, cutoffControlValue1: 200, Q1: 3 },
        { cutoffControlValue: 5000, Q: 6, cutoffControlValue1: 300, Q1: 2 },
        { cutoffControlValue: 1700, Q: 5, cutoffControlValue1: 300, Q1: 1 },

      ],
      selectedPresetIndex: 0,
    };
    this.noiseNode = null;

    this.gainNode = this.audioContext.createGain();
    this.gainNode.gain.value = this.state.volume;

    this.biquadNode = this.audioContext.createBiquadFilter();
    this.biquadNode.type = "lowpass";
    this.biquadNode.frequency.value = 500;
    this.biquadNode.Q.value = 1;

    this.biquadNode1 = this.audioContext.createBiquadFilter();
    this.biquadNode1.type = "lowpass";
    this.biquadNode1.frequency.value = 500;
    this.biquadNode1.Q.value = 1;

    this.hiPass = this.audioContext.createBiquadFilter();
    this.hiPass.type = "highpass";
    this.hiPass.frequency.value = 100;
    this.hiPass.Q.value = 1;

  }

  componentDidMount() {
    this.noiseNode = this.audioContext.createScriptProcessor(4096, 1, 1);
    this.noiseNode.onaudioprocess = this.generateNoise;

    this.noiseNode.connect(this.biquadNode);
    this.biquadNode.connect(this.biquadNode1);
    this.biquadNode1.connect(this.hiPass);
    this.hiPass.connect(this.gainNode);
    this.gainNode.connect(this.audioContext.destination);

  }

  componentWillUnmount() {
    this.noiseNode.disconnect();
    this.biquadNode.disconnect();
    this.biquadNode1.disconnect();
    this.gainNode.disconnect();
  }

  generateNoise = (event) => {
    const outputBuffer = event.outputBuffer;
    const outputData = outputBuffer.getChannelData(0);

    for (let i = 0; i < outputBuffer.length; i++) {
      outputData[i] = Math.random() * 2 - 1;
    }
  };


  handleVolumeChange = (event) => {
    const volume = event.target.value;
    this.setState({ volume });
    if (this.gainNode) {
      this.gainNode.gain.value = volume;
    }
  };


  handleStartAudio = () => {
    if (!this.state.isPlaying) {
      this.audioContext.resume().then(() => {
        console.log('Audio context resumed');
        this.setState({ isPlaying: true });
      });
    } else {
      this.handlePauseAudio();
    }
  };

  handlePauseAudio = () => {
    this.audioContext.suspend().then(() => {
      console.log('Audio context paused');
      this.setState({ isPlaying: false });
    });
  };


  handlePresetChange = (index) => {
    if (Number.isInteger(index) && index >= 0 && index < this.state.presets.length) {
      const preset = this.state.presets[index];

      this.setState({
        selectedPresetIndex: index,
        cutoffControlValue: preset.cutoffControlValue,
        Q: preset.Q,
        cutoffControlValue1: preset.cutoffControlValue1,
        Q1: preset.Q1,
      });
      if (this.biquadNode) {
        this.biquadNode.frequency.value = preset.cutoffControlValue;
        this.biquadNode.Q.value = preset.Q;
      }
      if (this.biquadNode1) {
        this.biquadNode1.frequency.value = preset.cutoffControlValue1;
        this.biquadNode1.Q.value = preset.Q1;
      }
    } else {

    }
  };


  render() {
    return (
      <div className="App">
        <div className="centered">
          <div className="start-engine">
            <button className="start-audio-button" onClick={this.handleStartAudio}>
              {this.state.isPlaying ? "Pause" : "Listen"}
            </button>
          </div>
        </div>
        <div className="user-interface">
          <div className="preset-container">
            <div className="preset-item">
              <label className="preset-label">Volume</label>
            </div>
            <div className="preset-item">
              <input
                className="preset-slider"
                type="range"
                min="0"
                max="1"
                step="0.01"
                value={this.state.volume}
                onChange={this.handleVolumeChange}
                orient="horizontal"
              />
            </div>
            <div className="preset-item">
              <label className="preset-label">Character</label>
            </div>
            <div className="preset-item">
              <input
                className="preset-slider"
                type="range"
                min={0}
                max={this.state.presets.length -1}
                value={this.state.selectedPresetIndex}
                onChange={(e) => this.handlePresetChange(parseInt(e.target.value))}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NoiseGenerator;
