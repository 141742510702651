import React, { useState} from 'react';
import NoiseGenerator from  './NoiseGenerator';
import PrivacyPolicyModal from './PrivacyPolicyModal';
import './App.css';


function Main() {
  const [backgroundGradient] = useState("linear-gradient(33deg,  skyblue, purple)"); // Initial gradient is black to black
  const openPrivacyPolicy = () => {
    window.location.href = '/privacy-policy';
  }

  return (
    <div className="App" style={{ background: backgroundGradient }}>
      <div>
        <NoiseGenerator />
      </div>
      <button className="privacy-policy-button" onClick={openPrivacyPolicy}>Privacy Policy</button>
    </div>
  );
}


export default Main;
