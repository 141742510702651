import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Main from './Main';
import PrivacyPolicyModal from './PrivacyPolicyModal';

const Hold = () => {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<Main />} />
        <Route path='/privacy-policy' element={<PrivacyPolicyModal />} />
      </Routes>
    </Router>
  );
}

export default Hold;
